@import 'common/variables';
@import 'common/colors';
@import 'common/buttons';
@import 'common/functions';
@import 'common/mixins';
@import 'common/dropdowns';
//@import 'common/forms';


.danger-info {
  text-align: center;
  padding: 10px;
  border: 2px solid $red;
  border-radius: 5px;
  svg {
    color: $red;
    font-size: 20px;
  }
}