@import "../../../style/index";

#viewer {
  max-width: 90%;
}

#property-editor {
  h3 {
    @extend .blue;
  }
  label {
    font-weight: bold;
  }
  .dropdown-toggle{
    width: 100%;
    border-color: #d0d6db !important;
    color: #495057 !important;
  }
  .dropdown-menu.show {
    width: 100%;
    text-align: center;
    overflow-y: auto;
    max-height: 250px;
  }
}