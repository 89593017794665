@import "../../../style/index";

#town-list {
  thead {
    text-align: center;
    th {
      cursor: pointer;
      vertical-align: middle;
      text-align: center;
      svg {
        font-size: 20px;
      }
    }
  }
  tbody {
    .tr-btn:hover {
      background-color: transparent !important;
    }
    tr {
      &:hover {
        background-color: #8fc0e869 !important;
      }
      td {
        text-align: center;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}
