@import "buttons";
@import "colors";

.dropdown {
  button {
    @extend .btn;
    @extend .btn-bordered;
    @extend .btn-bordered-blue;
  }

  a:hover, .active {
    @extend .blue;
    background: transparent;
  }
}