@import "../../../style/index";

.toaster {
  margin: 0;
  z-index: 1030;
  position: fixed;
  width: 100%;
  max-width: 100%;
  text-align: center;
  transition: all .6s;
}

.success {
  background-color: $green!important;
  color: $white!important;
}

.warning {
  background-color: $yellow!important;
  color: $black!important;
}

.error {
  background-color: $red !important;
  color: $white!important;
}