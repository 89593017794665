@import "../../../../../../style/index";

.miniatures {
  p {
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }

  .carousel-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    min-height: 60px;
    li {
      cursor: pointer;
      margin-right: 10px;
      border-radius: 3px;
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s ease-in-out 0s;

      &:last-child {
        margin: 0;
      }

      img {
        height: 60px;
        width: auto;
      }
    }

    .selected {
      border: 2px solid $blue;
    }
  }
}