@import "../../style/index";

#dashboard-page {
  padding: px2rem(48);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    margin-top: 2em;
  }
  img {
    height: auto;
    display: block;
    margin: 0 auto;
  }
}