@import "../../../../../style/index";

$width-image: 150px;
$height-image: calc((3 / 4) * 150px);

#imageList {
  max-height: 250px;
  display: flex;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow-y: auto;
  li {
    list-style-type: none;
  }

  .image-property {
    list-style-type: none;
    height: $height-image;
    margin: px2rem(5);
    cursor: pointer;
    .image-wrap {
      position: relative;
      display: inline-block;
      font-size: 0;
      cursor: pointer;
      &:hover {
        .drag-text, .btn-remove {
          display: block;
          position: absolute;
          color: $white;
          font-size: 15px;
        }
        .drag-text {
          top: 40%;
          left: 0;
          width: 100%;
          z-index: 2;
          text-shadow: 1px 1px $black;
          text-align: center;
        }
        .btn-remove {
          top: 0;
          right: 0;
          z-index: 3;
          padding: 0;
          svg {
            font-size: px2rem(30);
          }
        }
        img {
          opacity: 0.5!important;
        }
      }
      .drag-text, .btn-remove {
        display: none;
      }
      img {
        width: $width-image;
        height: $height-image;
        object-fit: cover;
      }
    }
  }

  .placeholder {
    list-style: none;
    background: $blue;
    margin: px2rem(5);
    width: $width-image;
    height: $height-image;
    border-radius: 2px;
  }
}
