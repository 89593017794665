@import "../../../style/index";
#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.5);

  .loading-container {
    position: absolute;
    top: 10px;
    left: calc(50% - 150px);
    height: 300px;
    width: 300px;
  }

  .leftLine {
    position: absolute;
    top: 200px;
    left: 0;
    height: 8px;
    width: 124px;
    background-color: $blue;;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    animation: leftLine 2.5s infinite linear;
  }

  .leftUpLine {
    position: absolute;
    top: 178px;
    left: 116px;
    width: 8px;
    height: 0;
    background-color: $blue;;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    animation: leftUpLine 2.5s infinite linear;
  }

  .leftAngleLine {
    position: absolute;
    top: 180px;
    left: 116px;
    width: 8px;
    height: 0;
    background-color: $blue;;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    transform: rotate(-132deg);
    animation: leftAngleLine 2.5s infinite linear;
  }

  .rightAngleLine {
    position: absolute;
    top: 150px;
    left: 149px;
    width: 8px;
    height: 0;
    background-color: $blue;;
    transform: rotate(132deg);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    animation: rightAngleLine 2.5s infinite linear;
  }

  .rightUpLine {
    position: absolute;
    top: 178px;
    right: 116px;
    width: 8px;
    height: 0;
    background-color: $blue;;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    animation: rightUpLine 2.5s infinite linear;
  }

  .rightLine {
    position: absolute;
    top: 200px;
    right:0;
    height: 8px;
    width: 124px;
    background-color: $blue;;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    animation: rightLine 2.5s infinite linear;
  }

  @keyframes leftLine {
    from {
      left: 0;
      width: 0;
    }
    12.5% {
      left: 0;
      width: 124px;
    }
    50% {
      left: 0;
      width: 124px;
    }
    62.5% {
      left: 124px;
      width: 0;
    }
    to {
      left: 124px;
      width: 0;
    }
  }

  @keyframes leftUpLine {
    from {
      top: 208px;
      height: 0;
    }

    12.5% {
      top: 208px;
      height: 0;
    }
  
    17% {
      top: 178px;
      height: 30px;
    }
  
    62.5% {
      top: 178px;
      height: 30px;
    }
  
    67% {
      top: 178px;
      height: 0;
    }
  
    to {
      top: 208px;
      height: 0;
    }
  }
  
  @keyframes leftAngleLine {
    from {
      top:182px;
      left:116px;
      height:0;
    }
    17% {
      top:182px;
      left:116px;
      height:0;
    }
    25% {
      top:144px;
      left:131px;
      height:48px;
    }
    67% {
      top:144px;
      left:131px;
      height:48px;
    }
    75% {
      top:153px;
      left:147px;
      height:0;
    }
    to {
      top:153px;
      left:147px;
      height:0;
    }
  }
  @keyframes rightAngleLine {
    from {
      top:152px;
      left:144px;
      height:0;
    }
    25% {
      top:152px;
      left:144px;
      height:0;
    }
    33% {
      top:144px;
      left:161px;
      height:48px;
    }
    75% {
      top:144px;
      left:161px;
      height:48px;
    }
    83% {
      top:182px;
      left:176px;
      height:0;
    }
    to {
      top:182px;
      left:176px;
      height:0;
    }
  }
  @keyframes rightUpLine {
    from {
      top:178px;
      height:0;
    }
    33% {
      top:178px;
      height:0;
    }
    37.5% {
      top:178px;
      height:30px;
    }
    83% {
      top:178px;
      height:30px;
    }
    87.5% {
      top:208px;
      height:0;
    }
    to {
      top:208px;
      height:0;
    }
  }
  @keyframes rightLine {
    from {
      right:124px;
      width:0;
    }
    37.5% {
      right:124px;
      width:0;
    }
    50% {
      right:0;
      width:124px;
    }
    87.5% {
      right:0;
      width:124px;
    }
    to {
      right:0;
      width:0;
    }
  }
}