@import "../../style/index";

#towns-page {
  padding: px2rem(48);

  .title-container {
    display: flex;
    flex-direction: row;
    margin-bottom : px2rem(15);
    .btn {
      svg {
        font-size: px2rem(30);
      }
    }
  }
}