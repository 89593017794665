@import "../../style/index";

#properties-page {
  padding: px2rem(48);
  .title-container {
    display: flex;
    flex-direction: row;
    margin-bottom : px2rem(15);
    .btn {
      svg {
        font-size: px2rem(30);
      }
    }
  }
  .search-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: px2rem(15);
    form {
      flex-grow: 1;
      label {
        margin-right: px2rem(15);
      }
      input {
        width: auto;
      }
    }
    .btn {
      justify-self: flex-end;
    }

  }
}