@import "./variables";

/**
 * Colors
 */
.blue {
  color: $blue!important;
}
.dark-blue {
  color: $dark-blue!important;
}
.green {
  color: $green!important;
}
.dark-green {
  color: $dark-green!important;
}
.yellow {
  color: $yellow!important;
}
.dark-yellow {
  color: $dark-yellow!important;
}
.red {
  color: $red!important;
}
.dark-red {
  color: $dark-red!important;
}
.grey {
  color: $grey!important;
}
.dark-grey {
  color: $dark-grey!important;
}
.black {
  color: $black!important;
}
.light {
  color: $light!important;
}
.white {
  color:$white!important;
}

/**
 * Background colors
 */
.blue-bg {
  background-color: $blue!important;
}
.dark-blue-bg {
  background-color: $dark-blue!important;
}
.green-bg {
  background-color: $green!important;
}
.dark-green-bg {
  background-color: $dark-green!important;
}
.yellow-bg {
  background-color: $yellow!important;
}
.dark-yellow-bg {
  background-color: $dark-yellow!important;
}
.red-bg {
  background-color: $red!important;
}
.dark-red-bg {
  background-color: $dark-red!important;
}
.grey-bg {
  background-color: $grey!important;
}
.dark-grey-bg {
  background-color: $dark-grey!important;
}
.black-bg {
  background-color: $black!important;
}
.light-bg {
  background-color: $light!important;
}
.white-bg {
  background-color:$white!important;
}

/**
 * Border colors
 */
.blue-border {
  border-color: $blue!important;
}
.dark-blue-border {
  border-color: $dark-blue!important;
}
.green-border {
  border-color: $green!important;
}
.dark-green-border {
  border-color: $dark-green!important;
}
.yellow-border {
  border-color: $yellow!important;
}
.dark-yellow-border {
  border-color: $dark-yellow!important;
}
.red-border {
  border-color: $red!important;
}
.dark-red-border {
  border-color: $dark-red!important;
}
.grey-border {
  border-color: $grey!important;
}
.dark-grey-border {
  border-color: $dark-grey!important;
}
.black-border {
  border-color: $black!important;
}
.light-border {
  border-color: $light!important;
}
.white-border {
  border-color:$white!important;
}

