@import "variables";

@mixin boxShadow($blur) {
  -webkit-box-shadow: 0px 0px $blur -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px $blur -1px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px $blur -1px rgba(0, 0, 0, 1);
}

@mixin for-tablet-portrait-up {
  @media (min-width: $sm-width) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $md-width) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: $lg-width) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: $xl-width) { @content; }
}

@mixin for-tablet-portrait-down {
  @media (max-width: $sm-width) { @content; }
}
@mixin for-tablet-landscape-down {
  @media (max-width: $md-width) { @content; }
}
@mixin for-desktop-down {
  @media (max-width: $lg-width) { @content; }
}
@mixin for-big-desktop-down {
  @media (max-width: $xl-width) { @content; }
}