@import "../../style/index";

#menu-container {
  @include boxShadow(5px);
  background-color: $dark-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  max-height: 100vh;
  top: 0;
  &:hover {
    min-width: 160px;
  }

  .logout {
    margin-bottom: 1.25rem!important;
  }

  /**
   * Menu component
   **/
  .menu {
    list-style-type: none;
    display: block;
    color: $blue;
    .menu-text {
      cursor: pointer;
      color: $blue;
      font-size: 16px;
      &:hover {
        color: $blue!important;
        text-decoration: underline!important;
      }
      svg {
        display: inline-block;
        width: 20px;
        font-size: 20px;
        text-align: center;
      }
      span { display: none; }
    }
  }

  /**
   * Active Menu
   **/
  .active {
    .menu-text {
      color: $blue!important;
      &:hover {
        text-decoration-color: $blue!important;
      }
    }
  }


  /**
   * Show text when hover menu container
   **/
  &:hover {
    .menu-list{
      text-align: left;
    }
    .menu-text {
      svg {
        display: inline-block;
        text-align: center;
        margin-right: 10px;
      }
      span {
        display: inline;
      }
    }
    .show {
      display: block;
    }
  }

  /**
   * Main menu list
   **/
  .menu-list {
    padding: px2rem(20);
    margin: 0;
    text-align: center;
    .menu { padding-bottom: px2rem(20); }
    li:last-child { padding-bottom: 0!important; }
  }

  /**
   * Submenu list
   **/
  .submenu-list {
    padding: px2rem(10);
    margin-top: px2rem(10);
    border: 1px solid $blue;
    display: none;
    .menu { padding-bottom: px2rem(10); }
    li:last-child { padding-bottom: 0!important; }
  }
}