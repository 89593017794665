@import "../../style/index";

#property-page {
  padding: px2rem(48);

  .footer-btn {
    display: flex;
    flex-direction: row-reverse;
    .btn {
      margin-left: px2rem(10);
    }
  }
}