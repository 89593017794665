$baseFontSize: 16;

@function px2rem($pixels, $context: $baseFontSize) {
  @if unitless($pixels) {
    $pixels: $pixels * 1px;
  }

  @if unitless($context) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}