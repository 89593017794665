@import "../../../../../style/index";
#images-property-grid {
  height: 400px;
  cursor: pointer;

  .images-row {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    .btn-open-modal {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
    .main-box {
      flex-grow: 2;
    }
    .box {
      flex-grow: 1;
      @include for-tablet-landscape-down {
        display: none;
      }
      &:first-child {
        @include for-tablet-landscape-down {
          display: block;
        }
      }
    }
    .image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border: 2px solid $white;
      border-radius: 10px;
      height: 100%;
      width: 100%;
    }

    .button-container {
      flex-grow: 1;
      position: relative;
      .box {
        flex-grow: 0;
        height: 100%;
      }
      .btn-open-modal {
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
    }
  }
}