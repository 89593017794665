@import "../../../style/index";

#table-properties {
  thead {
    text-align: center;
    th {
      cursor: pointer;
      vertical-align: middle;
      text-align: center;
      svg {
        font-size: 20px;
      }
    }
  }
  tbody {
    .tr-btn:hover {
      background-color: transparent !important;
    }
    tr {
      &:hover {
        background-color: #8fc0e869 !important;
      }
      td {
        text-align: center;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        cursor: pointer;

        .container-img {
          position: relative;
          text-align: center;
          padding-top: calc((3 / 4) * 100%);

          img {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .label-sold {
        p {
          border: 1px solid $dark-red;
          background-color: $red;
          color: $white;
          letter-spacing: .06em;
          font-weight: bold;
          text-shadow: 0 0 0.75em #444;
          border-radius: 5%;
          padding: 10px;
        }
      }
      .label-sale {
        p {
          border: 1px solid $dark-green;
          background-color: $green;
          color: $white;
          letter-spacing: .06em;
          font-weight: bold;
          text-shadow: 0 0 0.75em #444;
          border-radius: 5%;
          padding: 10px;
        }
      }
    }
  }
  .main-image {
    display: block;
    margin: 0 auto;
    width: 82px;
    height: 66px;
  }
  .buttons-container {
    display: flex;
    justify-content: space-around;
    .btn {
      min-width: 25%;
    }
  }
}