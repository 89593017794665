@import "../../../../style/index";

#images {
  .btn-bordered-green {
    font-size: px2rem(20);
    border-width: 2px!important;
  }

  .uploaded-images {
    .btn {
      margin: px2rem(5);
    }
  }

  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}