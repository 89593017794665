@import "../../style/index";

#login {
  height: 100vh;
  display: flex;
  background-color: $blue;
  .image-container {
    flex-grow: 2;
    background-position: center;
    background-size: cover;
    @include  for-tablet-landscape-down {
      flex-grow: 1;
    }
    @include for-tablet-portrait-down {
      flex-grow: 0;
    }
  }
  .login-container {
    color: $white!important;
    align-self: center;
    flex-grow: 1;
    display: flex;
    form {
      padding: 0 45px;
      flex-grow: 1;
      img {
        display: block;
        margin: 0 auto;
      }
      .btn {
        float: right!important;
      }
    }
  }
}