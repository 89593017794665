@import "../../style/index";

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 2em;
  background-color: $grey;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: flex-end;
  .container {
    text-align: left;
    padding: 15px;
    display: flex;
  }
  .description-block {
    img {
      margin: 0!important;
    }
    img, h5 {
      display: inline-block;
      margin-bottom: 8px;
    }
  }

  .copyright-footer {
    background-color: $grey;
    padding: 5px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    p {
      margin: 0;
      font-size: 11px;
    }
  }

  .heading-title-columns {
    font-weight: bold;
  }

  .body-text-columns {
    margin-top: 15px;
    text-align: justify;
  }

  .footer-link {
    color: #ffffff;
    font-weight: bold;;
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}