@import "../../../../style/index";

#amenities {
  .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin: px2rem(15) 0;
  }
}