@import "../../../style/index";

#pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  * {
    margin: 0 5px;
  }

  .btn {
    font-size: 1rem;
    padding: 3px 10px;
  }
  .btn-rounded {
    -webkit-box-pack: center !important;
    -webkit-box-align: center !important;
    font-weight: 600 !important;
    text-align: center;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 32px !important;
    width: 32px !important;
    border-radius: 50% !important;
  }
  .btn-arrows {
    margin: 0 15px;
    font-size: 1.5rem;
    &:hover {
      color: $dark-blue!important;
    }
  }
}
