@import "../../../style/index";

#modal-create-town {
  p {
    text-align: center;
  }
  form {
    justify-content: center;
    .form-row {
      margin-bottom: px2rem(15);
    }
    label {
      margin-right: px2rem(15);
    }
  }
}